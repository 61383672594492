import React, { FunctionComponent, useRef, useMemo, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { Button, Box, Typography, styled } from "@material-ui/core";
import OptionsTypeWeb from "./OptionType.web";
import SliderType from "./SliderType.web";
import PrintOuter from "./PrintOuter";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { getColors } from "./color";

interface HeaderWithCount {
  header: string;
  questionCount: number;
}

interface QuestionData {
  id: string;
  title: string;
  data: {
    type: 'radio' | 'checkbox' | 'slider' | 'ranking' | 'date';
    analytics: any[];
  };
}

interface OverviewData {
  title: string;
  duration: number;
  respondents: number;
  group_names: string[];
  graph_details: any[];
}



interface QuestionAnalytics {
  optionId?: number;
  optionName?: string;
  percentage?: string;
  number_of_respondents?: number;
  score?: number;
  rank?: number;
  user_rank?: number;
}

interface QuestionData {
  type: string;
  analytics?: QuestionAnalytics[] | null;
  answer: any;
  date_answer: string | null;
  slider: any;
  option_ids: any;
  respondents: number;
  max_label?: string;
  max_value?: number;
  min_label?: string;
  min_value?: number;
  avg_value?: number | null;
  avgPercent?: number | null;
}

interface Question {
  id: number;
  title: string;
  data: QuestionData;
}

interface Section {
  header: string;
  questions: Question[];
}

type Datas = Section[];


interface Props {
  Data:Datas;
  questionData: QuestionData[];
  surveyNumber: number;
  overviewData: OverviewData;
}

const PrintOnly = styled(Box)({
  '@media print': {
    display: 'block',
    width: '100%',
    color: '#000',
    paddingTop: '50px',
    paddingBottom: '50px',
    backgroundColor: '#fff',
    pageBreakInside: 'auto',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
  },
  '@media screen': {
    visibility: 'hidden',
    height: "0px",
    width: "0px"
  },
});

const Print: FunctionComponent<Props> = ({
  Data,
  questionData,
  surveyNumber,
  overviewData
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);

  const isButtonDisabled = !( Data && questionData &&  surveyNumber && overviewData);
  let HeaderQuestion:string="";
  const formattedDataMap = useMemo(() => {
    return questionData.reduce((acc, question) => {
      if (question.data.type === 'radio') {
        const formattedData = question.data.analytics.map((unit: any) => ({
          ...unit,
          percentage: +unit.percentage || 0,
        }));
        const variousColors = getColors(formattedData.length);
        acc[question.id] = { formattedData, variousColors };
      }
      return acc;
    }, {} as Record<string, { formattedData: any[], variousColors: any[] }>);
  }, [questionData]);

  const getComponent = (question: QuestionData) => {
    const { formattedData, variousColors } = formattedDataMap[question.id] || { formattedData: [], variousColors: [] };
    switch (question.data.type) {
      case 'radio':
        return renderRadioType(formattedData, variousColors);
      case 'checkbox':
        return <OptionsTypeWeb data={question.data.analytics} key={question.id} />;
      case 'slider':
        return <SliderType data={question.data.analytics} key={question.id} />;
      case 'ranking':
        return <RankingQuestion data={question.data} key={question.id} />;
      default:
        return <Box data-test-id="empty-box-default" />;
    }
  };

  

  let globalIndex = 1;
  const filteredQuestionszz = Data?.map((section: {
    header: string; questions: any[] 
  }) => ({
    ...section,
    header: section?.header ?? "", 
    questions: section?.questions
      ?.map((question) => ({
        ...question,
        globalIndex: globalIndex++, 
      }))
      ?.filter((question) =>
        ['radio', 'checkbox', 'slider', 'ranking'].includes(question?.data?.type)
      ) ?? [] 
  })) ?? []; 

  

const sectionWithFirstQuestionIndex = filteredQuestionszz
  .map((section) => ({
    header: section.header,
    indexCount: section.questions.length > 0 
      ? section.questions[0].globalIndex 
      : null 
  }))
  .filter((section) => section.indexCount !== null); 

  const filteredQuestions = filteredQuestionszz.flatMap((section) => section.questions);
  const getHeaderForQuestion = (questionIndex: number,question:any) => {
    const headerInfo = sectionWithFirstQuestionIndex.find(({ indexCount }) => indexCount === questionIndex);
    HeaderQuestion=headerInfo ? headerInfo.header : "";
    return HeaderQuestion;    
  };
  
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @media print {
        @page {
          margin: 20px 0;
        }
        body {
          background-color: #fff;
        }
        .print-container {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  return (
    <>
    <div>
      <ReactToPrint
        trigger={() => (
          <ExportButton
            href="#"
            data-test-id="export-button"
            disabled={isButtonDisabled}
          >
            Export PDF
          </ExportButton>
        )}
        content={() => selectorRef.current!}
        onBeforeGetContent={() => new Promise(resolve => setTimeout(() => resolve(100), 200))}
      />
       <div ref={selectorRef} id="print component" >
        {isButtonDisabled ? null : (
            <PrintOnly>
              <PrintOuter surveyNumber={surveyNumber} overviewData={overviewData} />
              {filteredQuestions.map((question: any) => (
                <OuterContainer key={question.globalIndex}>
                  {getHeaderForQuestion(question.globalIndex ,question) && (
                    <HeaderTitle variant="h1">
                      {getHeaderForQuestion(question.globalIndex ,question)}
                    </HeaderTitle>
                  )}
                  <ContentWrapper>
                    <Header style={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#9FA0A1", fontWeight: 400, marginRight: "10px", whiteSpace: "nowrap", fontSize: "18px" }}
                        className="headingSurvey"
                      >
                        Question {question.globalIndex}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#1C386D", fontWeight: 700, fontSize: "18px" }}
                        className="headingOverview"
                      >
                        {question.title}
                      </Typography>
                    </Header>
                    <ContentData>
                      {getComponent(question)}
                    </ContentData>
                  </ContentWrapper>
                </OuterContainer>
              ))}
            </PrintOnly>
          )}
        </div>
      </div>
    </>
  );
};

const ExportButton = styled(Button)({
  borderRadius: "8px",
  border: "1px solid #304a7c",
  fontFamily: 'aeonik-bold',
  textTransform: "capitalize",
  color: "#304a7c",
  fontWeight: 600,
  padding: "6px 10px",
  textDecoration: "none",
});

const OuterContainer = styled(Box)({
  width: "100%",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media print': {
    pageBreakInside: 'avoid',
  },
});

const ContentWrapper = styled(Box)({
  width: '65%',
  padding: '0 17.5%',
});

const Header = styled(Box)({
  padding: "32px 0",
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: '0.5em',
  justifyContent: "normal",
});

const ContentData = styled(Box)({
  overflowY: "auto",
  height: "75%",
  alignItems: 'center',
  padding: "10px 0",
  boxSizing: "border-box",
  width: "100%",
});

const HeaderTitle = styled(Typography)({
  background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
  color: '#FFFFFF',
  padding: '7px 0px',
  textAlign: 'center',
  margin: '5px auto',
  fontFamily: 'aeonik-regular',
  fontSize: '28px',
  width: "100%",
  maxWidth: '100%',
});

const RankingQuestion: FunctionComponent<{ data: { analytics: any[] } }> = ({ data }) => (
  <div style={{ width: "100%" }}>
    {data.analytics.map(({ option, rank }) => (
      <RankWrapper key={option.id}>
        <NameWrapper>{option.name}</NameWrapper>
        <RankDetails>
          <RankBox>
            <RankValue>Rank {rank}</RankValue>
          </RankBox>
        </RankDetails>
      </RankWrapper>
    ))}
  </div>
);

const RankWrapper = styled('div')({
  background: '#f5f6f8',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  padding: '10px',
  borderRadius: '7px',
  alignItems: 'center',
});

const NameWrapper = styled(Typography)({
  width: '50%',
  fontSize: '13px',
  fontFamily: 'aeonik-regular',
});

const RankDetails = styled('div')({
  display: 'flex',
});

const RankBox = styled('div')({
  backgroundColor: '#e4e8f3',
  padding: '4px 8px',
  borderRadius: '4px',
  marginRight: '10px',
});

const RankValue = styled('span')({
  fontSize: '12px',
  fontWeight: 'bold',
  fontFamily: 'aeonik-regular',
});

const renderRadioType = (formattedData: any[], variousColors: any[]) => {
  let show = formattedData.some((item:any) => parseFloat(item.percentage) !== 0.0);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
       { show === false ? <Box style={{fontSize: "20",fontFamily: 'aeonik-regular', paddingBottom:"20px"}}>No responses</Box> : (
        <>
      <PieChart width={730} height={450} style={{ color: 'black' }}>
        <Pie
          data={formattedData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
            if (percent === 0) return null;
            const RADIAN = Math.PI / 180;
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
              <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif', fontWeight: 700 }}
              >
                {`${(percent * 100).toFixed(0)}%`}
              </text>
            );
          }}
          outerRadius={130}
          innerRadius={80}
          fill="#8884d8"
          dataKey="percentage"
          nameKey="optionName"
        >
          {formattedData.map((entry, ind) => (
            <Cell key={`cell-${ind}`} fill={variousColors[ind].barColor} />
          ))}
        </Pie>
      </PieChart>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '20px',
          width: '99%',
          color: 'black',
        }}
      >
        {formattedData.map((entry, ind) => (
          <div
          key={entry.optionName}
          style={{
              padding:"16px 12px",
              display: 'flex',
              margin: '0 10px 10px 0',
              fontFamily: 'aeonik-regular',
              color: 'black',
            }}
          >
            <div
              style={{
                width: '17px',
                height: '17px',
                minWidth:"17px",
                backgroundColor: variousColors[ind].barColor,
                borderRadius: '50%',
                marginRight: '5px',
                flexShrink: 0,
                marginTop:"5px"
              }}
            ></div>
             <span style={{ fontSize: "16px", color: "black", wordBreak: 'normal', marginRight: "25px" ,marginLeft: "12px" , }}>
             {entry.optionName}
              </span>
          </div>
        ))}
      </div>
        </>
       )}
    </div>
  );
};

export default Print;
