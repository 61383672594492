import React from "react";

import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ListItemText,
  Checkbox,
  TextField,
  Popover,
  styled
  // Customizable Area End
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import ExportAnalyticsController, { Props } from "./ExportAnalyticsController.web";
import DateRange from "../../../components/src/DateRange.web";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Calendar } from 'react-multi-date-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// Customizable Area End

export class ExportAnalytics extends ExportAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { surveyList, selectedSurveyId, showDateRangePicker, startDate, endDate } = this.state;
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <Box className={classes.content} style={{ flexDirection: "column",padding:'0px 40px 40px' }}>
          <Grid item xs={12} style={{ position: "relative" }}>
            <InputLabel className={classes.label}>Select survey*</InputLabel>
            <Select
              style={{width:'350px',marginTop:'10px',borderRadius:'10px'}}
              multiple
              variant="outlined"
              value={this.state.selectedIds}
              onChange={this.handleSurveyChange}
              displayEmpty
              renderValue={(selected:any) => selected.map((id:number) => surveyList.find((item:any) => item.attributes?.id == id)?.attributes.name).join(', ')}
              inputProps={{ "aria-label": "Without label" }}
            >
              {surveyList.map((unitUsrvey: any, ind: number) => (
                <MenuItem data-test-id={`menu-item-${unitUsrvey.attributes.id}`} onClick={() => this.handleMenuItemClick(unitUsrvey?.attributes?.id)} value={unitUsrvey?.attributes?.id} key={unitUsrvey?.attributes?.id}>
                  <ListItemText primary={unitUsrvey?.attributes?.name} />
                  <Checkbox
                    style={{color:'#4B6EB0'}}
                    checked={this.state.selectedIds.includes(unitUsrvey?.attributes?.id)}
                  />
                </MenuItem>
              ))}
            </Select>
            <InputLabel className={classes.label}>Select date</InputLabel>
            <CustomTextField
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              data-test-id="customtextfield"
              style={{width:'350px',marginTop:'10px',borderRadius:'10px'}}
              variant="outlined"
              fullWidth
              onClick={this.openPopover}
              value={this.state.dates.length === 2 ? `${this.state.dates[0].format()} - ${this.state.dates[1].format()}` : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MyPopover
              data-test-id="popover"
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={this.closePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div style={{ padding: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px' }}>
                  <Typography style={{ fontWeight: 500, fontSize: '20px',fontFamily:'aeonik-bold' }}>From</Typography>
                  <Typography style={{ marginLeft: '33%', fontWeight: 500 ,fontFamily:'aeonik-bold',fontSize: '20px'}}>To</Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className={classes.datepickerWrap}>
                    <Calendar
                      format="DD/MM/YYYY"
                      maxDate={today}
                      data-test-id="calendar"
                      value={this.state.dates}
                      onChange={this.handleDateChange}
                      weekStartDayIndex={1}
                      weekDays={weekDays}
                      numberOfMonths={2}
                      range
                      showOtherDays
                      highlightToday={false}
                      rangeHover
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginLeft: '20px' }}>
                    <CalendarButtons data-test-id="this-month" onClick={this.handleThisMonthClick}>This month</CalendarButtons>
                    <CalendarButtons data-test-id="last-month" onClick={this.handleLastMonthClick}>Last month</CalendarButtons>
                    <CalendarButtons data-test-id="3-month" onClick={this.handleLast3MonthsClick}>Last 3 months</CalendarButtons>
                    <CalendarButtons data-test-id="6-month" onClick={this.handleLast6MonthsClick}>Last 6 months</CalendarButtons>
                    <CalendarButtons data-test-id="12-month" onClick={this.handleLast12MonthsClick}>Last 12 months</CalendarButtons>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '17px' }}>
                  <ApplyButton data-test-id="apply-button" onClick={this.handleApplyClick} style={{ width: '19%' }}>Apply</ApplyButton>
                </div>
              </div>
            </MyPopover>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
            <Button disabled={!this.state.selectedIds.length} id="download-button" onClick={this.downlaodExcelReport} size="large" data-test-id={`export-survey-data-button`} className={classes.btn}>
              Export
            </Button>
          </Grid>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CalendarButtons = styled(Button)({
  padding: '8px 14px',
  border: "1px solid lightgrey",
  color: "#2d3e5e",
  borderRadius: '8px',
  fontWeight: 600,
  textTransform: 'none',
  fontFamily:'aeonik-regular'
});

const ApplyButton = styled(Button)({
  fontFamily:'aeonik-regular',
  padding: '8px 14px',
  textTransform: 'none',
  backgroundColor: '#2d3e5e',
  borderRadius: '8px',
  fontWeight: 600,
  color: 'white',
  '&:hover': {
    backgroundColor: '#2d3e5e', 
  },
  zIndex: 10
});

const MyPopover = styled(Popover)({
  '& .MuiPaper-root': {
  },
});
const CustomTextField = styled(TextField)({
   
  '& .MuiOutlinedInput-root':{
    borderRadius:'8px'
  }
})
const styles = {
  mainContainer: {
    border: "1px solid #ddd",
    margin: "30px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    padding: "32px",
    flex: "1 1 0px",
    "@media (max-width: 500px)": {
      margin: "20px",
      minHeight: "70vh",
    },
    zIndex: 10,
  },
  datepickerWrap:{
    display:'flex',
    alignItems:'center',
    '& .rmdp-week-day':{
      color:'black',
      fontWeight:600,
      fontFamily:'aeonik-regular'
    },
    '& .rmdp-arrow':{
      border: 'solid grey !important',
      borderWidth:'0px 2px 2px 0px !important'
    },
    '& .rmdp-day.rmdp-selected span:not(.highlight)':{
      backgroundColor:'#4d6fb4 !important',
      color:'white !important'
    },
    '& .rmdp-header-values':{
      fontFamily:'aeonik-regular',
      fontSize:'14px',
    },
    '& .rmdp-header':{

      fontWeight:'bold' as const,
    },
    '& .rmdp-day':{
      fontFamily:'aeonik-regular',
      fontWeight:100,
    },
    '& .rmdp-range':{
      backgroundColor:'#E8EBFA',
      boxShadow:'none',
      color:'black'
    }

  },
  header: {
    height: "48px",
    borderBottom: "1px solid #dedede",
  },
  heading: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    color: "#202020",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    height: "62vh",
  },
  label: {
    marginTop: "22px",
    fontFamily:'aeonik-regular',
    fontSize:'16px',
    fontWeight:'bolder' as const
  },
  btn: {
    borderRadius: "8px",
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: 'aeonik-regular',
    fontSize:'16px',
    textTransform: "capitalize" as const,
    padding: "8px 25px",
    cursor:"pointer",
  },
  buttonContainer: {
  },
  inputLength: {
    width: "30%",
    marginTop:'10px'
  },
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(ExportAnalytics);
// Customizable Area End
