import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { DateObject } from 'react-multi-date-picker';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  surveyList: any;
  selectedSurveyId: number;
  startDate: any;
  endDate: any;
  showDateRangePicker: boolean;
  selectedIds:any;
  anchorEl:any;
  dates:any[];
  activeButton:string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ExportAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getListSurveysCallId: string = "";
  postSurveyDetailsCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receiveApi.bind(this);
    // Customizable Area Start
    this.state = {
      surveyList: [],
      selectedSurveyId: 0,
      startDate: "",
      endDate: "",
      showDateRangePicker: false,
      selectedIds:[],
      anchorEl:null,
      dates:[],
      activeButton:'',
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receiveApi(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (!requestCallId || !apiResponseJson) {
      return;
    }
    if (requestCallId === this.getListSurveysCallId && apiResponseJson) {
      this.surveyListReceive(apiResponseJson);
    }
    if (requestCallId === this.postSurveyDetailsCallId && apiResponseJson) {
      this.exportSurveyReceive(apiResponseJson);
    }

    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    const urlSearchParams = new URLSearchParams(window.location.search);
    const surveyId = urlSearchParams.get("surveyId");
    if (surveyId) {
      this.setState({ selectedSurveyId: +surveyId || 0 });
    }
    await Promise.all([this.getListSurveys()]);
    // Customizable Area End
  }

  getListSurveys = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveyListApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSurveyListApiMethod
    );

    this.getListSurveysCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  surveyListReceive = (responseJson: any) => {
    // Customizable Area Start
    if (responseJson?.surveys?.data) {
      const surveys = responseJson.surveys.data?.filter((el:any)=> el.attributes.status=="past" || el.attributes.status=="live")
      this.setState({
        surveyList: surveys
      });
    }
    // Customizable Area End
  };

  // Customizable Area Start
  setActiveButton = (button: string) => {
    this.setState((prevState) => ({
      activeButton: prevState.activeButton === button ? '' : button,
    }));
  };
  downlaodExcelReport = () => {
    let postData = {
      survey_ids:this.state.selectedIds,
      start_date:this.state.startDate,
      end_date:this.state.endDate
    };
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_surveys/surveys/export_dashboard`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(postData)
    );
    this.postSurveyDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  exportSurveyReceive = (response:any) => {
    this.jsonToExcel(response)
  }
  jsonToExcel = async (jsonReport:any) => {
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    const headers = [
      'Survey name', 'First name', 'Last name', 'Email address', 
      'Associated groups', 'Local authority', 'Industry sector', 
      'Date shared', 'Date filled', 'Question', 'Question type', 
      'Response'
    ];
  
    const columnWidths = [35, 25, 25, 45, 30, 30, 30, 20, 20, 55, 20, 70];
    worksheet.columns = headers.map((header, index) => ({
      header,
      key: header.toLowerCase().replace(/ /g, '_'),
      width: columnWidths[index]
    }));
  
    jsonReport.forEach((data:any) => {
      worksheet.addRow({
        survey_name: data.survey_name,
        first_name: data.first_name,
        last_name: data.last_name,
        email_address: data.email_address,
        associated_groups: data.associated_groups,
        local_authority: data.local_authority,
        industry_sector: data.industry_sector,
        date_shared: data.date_shared,
        date_filled: data.date_filled,
        question: data.question,
        question_type: data.question_type,
        response: data.response
      });
    });
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell:any) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'left', vertical: 'top' };
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob,`Surveys Report.xlsx`,{ autoBom: false });
  };

  handleSurveyChange = (event: any) => {
    this.setState({
      selectedIds: event.target.value,
    });
  };
  handleMenuItemClick = (id:number) => {
    this.setState((prevState) => {
      const { selectedIds } = prevState;
      return {
        selectedIds: selectedIds.includes(id)
          ? selectedIds.filter((selectedId:number) => selectedId !== id)
          : [...selectedIds, id]
      };
    });
  };
  
  openPopover = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleApplyClick = () => {
    this.setState({ anchorEl: null });
  };

  handleThisMonthClick = () => {
    this.setActiveButton('thisMonth');
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    

    this.setState({
      dates: [new DateObject(startOfMonth), new DateObject(now)],
      startDate: new DateObject(startOfMonth).format("YYYY-MM-DD"),
      endDate: new DateObject(now).format("YYYY-MM-DD")
    });
  };

  handleLastMonthClick = () => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    const startOfLastMonth = new Date(currentYear, currentMonth - 1, currentDay);
    const endOfLastMonth = new Date(currentYear, currentMonth, 0);
    const adjustedStartDate = currentDay > endOfLastMonth.getDate() 
      ? endOfLastMonth 
      : startOfLastMonth;

    this.setState({
      dates: [new DateObject(adjustedStartDate), new DateObject(now)],
      startDate: new DateObject(adjustedStartDate).format("YYYY-MM-DD"),
      endDate: new DateObject(now).format("YYYY-MM-DD")
    });
  };

  handleLast3MonthsClick = () => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    const startOfThreeMonthsAgo = new Date(currentYear, currentMonth - 2, currentDay);
    const endOfPreviousMonth = new Date(currentYear, currentMonth, 0);
    const adjustedStartDate = currentDay > endOfPreviousMonth.getDate() 
      ? endOfPreviousMonth 
      : startOfThreeMonthsAgo;

    this.setState({
      dates: [new DateObject(adjustedStartDate), new DateObject(now)],
      startDate: new DateObject(adjustedStartDate).format("YYYY-MM-DD"),
      endDate: new DateObject(now).format("YYYY-MM-DD")
    });
  };

  handleLast6MonthsClick = () => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    const startOfSixMonthsAgo = new Date(currentYear, currentMonth - 5, currentDay);
    const endOfPreviousMonth = new Date(currentYear, currentMonth, 0);
    const adjustedStartDate = currentDay > endOfPreviousMonth.getDate() 
      ? endOfPreviousMonth 
      : startOfSixMonthsAgo;

    this.setState({
      dates: [new DateObject(adjustedStartDate), new DateObject(now)],
      startDate: new DateObject(adjustedStartDate).format("YYYY-MM-DD"),
      endDate: new DateObject(now).format("YYYY-MM-DD")
    });
  };

  handleLast12MonthsClick = () => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    const startOfTwelveMonthsAgo = new Date(currentYear, currentMonth - 11, currentDay);
    const endOfPreviousMonth = new Date(currentYear, currentMonth, 0);
    const adjustedStartDate = currentDay > endOfPreviousMonth.getDate() 
      ? endOfPreviousMonth 
      : startOfTwelveMonthsAgo;

    this.setState({
      dates: [new DateObject(adjustedStartDate), new DateObject(now)],
      startDate: new DateObject(adjustedStartDate).format("YYYY-MM-DD"),
      endDate: new DateObject(now).format("YYYY-MM-DD")
    });
  };

  handleDateChange = (newDates: any) => {
    if (newDates.length === 1) {
      this.setState({
        dates: [newDates[0]],
        startDate: newDates[0].format("YYYY-MM-DD"),
        endDate: null
      });
    } else if (newDates.length === 2) {
      this.setState({
        dates: newDates,
        startDate: newDates[0].format("YYYY-MM-DD"),
        endDate: newDates[1].format("YYYY-MM-DD")
      });
    }
  };
  openPicker = () => {
    this.setState({
      showDateRangePicker: true,
    });
  };

  closePicker = () => {
    this.setState({
      showDateRangePicker: false,
    });
  };

  handleDatePickerApply = (startDate: Date, endDate: Date) => {
    this.setState({
      startDate,
      endDate,
      showDateRangePicker: false,
    });
  };

  handleMouseDownPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  zeroPad = (toPad: number): string => {
    if (toPad < 10) {
      return `0${toPad}`;
    }
    return String(toPad);
  };

  formatDateAndTime = (stringDate: string | Date) => {
    const date = new Date(stringDate);
    return `${this.zeroPad(date.getDate())}/${this.zeroPad(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;
  };
  // Customizable Area End
}
